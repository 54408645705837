jQuery(document).ready(function ($) {
  $(window).keydown(function (event) {
    if (event.keyCode == 13) {
      event.preventDefault();
      return false;
    }
  });
  $("#signup_form input[name=email]").focus(function () {
    $("#signup_form input[name=cpt]").val("425");
  });

  $("#signup_form")
    // to prevent form from submitting upon successful validation
    .on("submit", function (ev) {
      ev.preventDefault();
      $("#success-message").hide();
      $("#error-message").hide();
      $("#signup_form button").attr("disabled", "true");
      var that = $("#signup_form"),
        url = that.attr("action"),
        method = that.attr("method"),
        data = {};

      that.find("[name]").each(function () {
        var that = $(this),
          name = that.attr("name"),
          value = that.val();
        data[name] = value;
      });

      if ($("#signup_form input[name=cpt]").val() == "425") {
        $.ajax({
          url: url,
          type: method,
          data: data,
          success: function (response) {
            console.log(response);
            var result = JSON.parse(response);
            console.log(result);
            if (result.status == "subscribed") {
              $("#success-message").show();
              $("#signup_form").hide();
            } else {
              $("#signup_form button").attr("disabled", false);
              $("#error-message").show();
              $("#error-message").text(result.detail);
            }
          },
          error: function (response) {
            $("#signup_form button").attr("disabled", false);
            $("#error-message").show();
            $("#error-message").text("Failed, try again.");
            console.log(response);
          },
        });
      }
    });
});
